/**
 * 图片预加载
 */
type Src = string

export class ImagePreload {
  private imagePool: Src[] = []
  private timer: null | number = null
  private callback: () => void = () => void 0
  private onProcessCallBack: () => void = () => void 0

  onLoad (callback: ImagePreload['callback']) {
    this.callback = callback;
  }

  onPrecess(callback: ImagePreload['onProcessCallBack']) {
    this.onProcessCallBack = callback;
  }

  loadImage (src: Src) {
    this.imagePool.push(src);

    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      const { imagePool } = this;
      this.imagePool = [];

      const onLoadPromises = [] as Promise<unknown>[];
      
      imagePool.forEach((src) => {
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => {
            resolve(void 0);
            removeImage();
            this.onProcessCallBack();
          }
          image.onerror = (e) => {
            reject(e);
            removeImage();
            this.onProcessCallBack();
          }

          image.width = 0;
          image.height = 0;
          image.style.display = 'none'
          
          image.src = src;
          document.body.appendChild(image)

          function removeImage () {
            document.body.removeChild(image);
          }
        })

        onLoadPromises.push(promise);
      });

      Promise.allSettled(onLoadPromises)
        .then(() => {
          this.callback();
        })
    }, 0);
  }
}