import { RESOURSE_VERSION } from '@/const'

export * from './image'

export const getCDNFullPath = (subPath: string) => {
  const url = process.env.NODE_ENV === 'development'
    ? './' + subPath
    : process.env.VUE_APP_OSS_PATH + process.env.VUE_APP_BASE_FRONT_CDN + subPath;
  return url;
}

/**
 * 在scritp setup作用域形成闭包，分割代码
 */
export function useClosure <T extends () => any>(fn: T): ReturnType<T> {
  return fn();
}


/**
 * 自适应的尺寸 以1920为准
 * @param value 
 * @param minValue 
 */
export function adaptiveSizing (value: number, minValue: number) {
  const size = parseInt((value / 1920 * document.body.scrollWidth).toString());
  if (typeof minValue === 'number') {
    return size > minValue ? size : minValue;
  } else {
    return size;
  }
}

/**
 * 引用pool-img中的图片
 */
export const usePoolImg = (fileName: string) => {
  // return require(process.env.VUE_APP_LOCAL_IMAGE_PATH + process.env.VUE_APP_POOL_IMAGE_PATH + '/' + fileName);
  const versionQuery = `?v=${RESOURSE_VERSION}`
  return process.env.VUE_APP_OSS_PATH + process.env.VUE_APP_POOL_IMAGE_PATH + '/' + fileName + versionQuery;
}

/**
 * 精确到1位小数
 */
export const getFixed1Value = (value: string) => {
  return parseFloat(value).toFixed(1);
}