import { HOME_PATH, IS_PRELOAD_STORAGE_KEY } from '@/const';
import { usePoolImg } from '@/utils';
import { ImagePreload } from '@/utils/image';
const IMAGES = {
  [process.env.VUE_APP_POOL_IMAGE_PATH]: ['login-pool.png', 'circular.png', 'irregular.png', 'rectangle.png', 'pool-circular-indoor-overground.png', 'pool-circular-outdoor-overground.png', 'pool-circular-outdoor-underground.png', 'pool-irregular-indoor-overground.png', 'pool-irregular-indoor-underground.png', 'pool-irregular-outdoor-overground.png', 'pool-irregular-outdoor-underground.png', 'pool-rectangle-indoor-overground.png', 'pool-rectangle-indoor-underground.png', 'pool-rectangle-outdoor-overground.png', 'pool-rectangle-outdoor-underground.png', 'step3-product-bg.png', 'product-7~20kW-back-side.png', 'product-7~20kW-left-midea.png', 'product-cad.png']
};
const LOADING_PAGE_SRC = '/preload';
const imagePreload = new ImagePreload();
let toPath = '';
export function usePreloadProcess() {
  let loadIndex = 0;
  let quantity = 0;
  const percentage = ref(0);
  const router = useRouter();
  imagePreload.onPrecess(() => {
    loadIndex++;
    percentage.value = Math.floor(loadIndex / quantity * 100);
  });
  imagePreload.onLoad(() => {
    sessionStorage.setItem(IS_PRELOAD_STORAGE_KEY, '1');
    percentage.value = 100;
    setTimeout(() => {
      if (toPath) {
        router.replace(toPath);
      } else {
        router.replace(HOME_PATH);
      }
    }, 500);
  });
  for (const key in IMAGES) {
    IMAGES[key].forEach(src => {
      quantity++;
      if (key === process.env.VUE_APP_POOL_IMAGE_PATH) {
        imagePreload.loadImage(usePoolImg(src));
      }
    });
  }
  return {
    percentage
  };
}
export function redirect2PreloadPageIfNeed(to) {
  const result = {
    isNext: true,
    redirectPath: ''
  };
  if (to.path === LOADING_PAGE_SRC) {
    return result;
  }
  if (!sessionStorage.getItem(IS_PRELOAD_STORAGE_KEY)) {
    toPath = to.path;
    result.isNext = false;
    result.redirectPath = LOADING_PAGE_SRC;
  }
  return result;
}