/**
 * 记录常量
 */

// 多语言标识
export const LANG_STORAGE_KEY = 'lang'

export const IS_PRELOAD_STORAGE_KEY = 'isPreload'

export const HOME_PATH = '/selection/stepPoolShape'

// 静态资源版本号
export const RESOURSE_VERSION = '1.0'