import { defineStore } from 'pinia'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const useUserStore = defineStore('user', {
	persist: {
		enabled: true,
		strategies: [{
			storage: window.localStorage,
			paths: [
				'brandKey',
			]
		}]
	},
	state: () => {
		return {
			brandKey: '',
			allowBrands: [
				'Midea'
			]
    }
	},
	actions: {
		routeChecker (toRoute: RouteLocationNormalized) {
			let isValidate = true;
			let redirectPath = '';

			const isToLogin = toRoute.path === '/login'

			if (!isToLogin 
				&& !this.allowBrands.includes(this.brandKey) 
				&& toRoute.meta.auth) {
				isValidate = false;
				redirectPath = '/login';
			}

			return {
				isValidate,
				redirectPath
			}
		}
	}
})