import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './locales'
import pinia from './stores'

import '@/assets/style/reset.scss'
import '@/assets/style/common.scss'

import 'element-plus/es/components/message/style/css'

createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .mount('#app')
