import { createI18n } from 'vue-i18n';
import { LANG_STORAGE_KEY } from '@/const';


const messages = loadLangs();

/**
 * 自动加载多语言配置文件
 */
function loadLangs () {
  const target = {} as Record<string, typeof import('./langs/en.json')>;
  const files = require.context('./langs', false, /\.json$/);

  const getLangName = (key: string) => {
    const matched = key.match(/\/([a-zA-Z]+)\.json$/);
    if (matched) {
      return matched[1];
    }
  }

  files.keys().forEach((key) => {
    const name = getLangName(key);
    if (name) {
      target[name] = files(key);
    }
  })

  return target
}


const i18n = createI18n({
  allowComposition: true,
  locale: localStorage.getItem(LANG_STORAGE_KEY) || 'en',
  messages,
  missingWarn: false,
  fallbackWarn: false,
});


export {
  messages
}

export default i18n